.advanced-calendar {
  display: block;
  width: 100%;
  border: 1px solid @primary-color;

  &__header {
    padding: .5em 0;
    background-color: @primary-color;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    &__previous,
    &__next {
      cursor: pointer;
      transition: .2s ease-out;
      color: #DDD;
      
      :hover {
        transform: scale(1.1);
        transition: .2s ease-out;
        color: #FFF;
      }
    }

    &__previous {
      margin-left: .5em;
    }

    &__current {
      display: flex;
      flex-direction: row;
      color: #FFF;

      &__month {
        text-transform: capitalize;
      }

      &__year {
        margin-left: .5em;
      }
    }

    &__next {
      margin-right: .5em;
    }
  }

  &__days-of-week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    padding: 0 .75em;
    border-bottom: 1px solid #DDD;
  }

  &__weeks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .75em;

    &__days {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__before {
        color: #CCC;
      }

      &__today {
        color: @primary-color;
      }

      &__before,
      &__today,
      &__after {
        &__appointments {
          border: 1px solid @primary-color;
          border-radius: 50%;
          cursor: pointer;
        }
      }

      &__popover {
        &__hidden {
          visibility: hidden;
          opacity: 0;
          display: none;
          border: 0;
          box-shadow: none;
          cursor: initial;
        }
      }
    }
  }
}
