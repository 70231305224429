html,
body {
  font-family: 'Poppins', sans-serif !important;
}

@import '~antd/dist/antd.less';

@import './_parameters';
@import './_globals';

// stacks
@import 'src/app/pages/Panel/PanelNavigationStack';

// pages
@import 'src/app/pages/Auth/Login/Login';
@import 'src/app/pages/Auth/PasswordRecovery/PasswordRecovery';
@import 'src/app/pages/Panel/Dashboard/Details/DashboardDetails';
@import 'src/app/pages/Panel/Admin/Report/AdminReport';
@import 'src/app/pages/Panel/Admin/Details/AdminDetails';
@import 'src/app/pages/Panel/PushNotification/Report/PushNotificationReport';
@import 'src/app/pages/Panel/PushNotification/Details/PushNotificationDetails';
@import 'src/app/pages/Panel/Restaurant/Report/RestaurantReport';
@import 'src/app/pages/Panel/Shopkeeper/Report/ShopkeeperReport';
@import 'src/app/pages/Panel/Shopkeeper/Details/ShopkeeperDetails';
@import 'src/app/pages/Panel/Store/Report/StoreReport';
@import 'src/app/pages/Panel/Store/Details/StoreDetails';
@import 'src/app/pages/Panel/Category/Report/CategoryReport';
@import 'src/app/pages/Panel/Category/Details/CategoryDetails';
@import 'src/app/pages/Panel/Product/Report/ProductReport';
@import 'src/app/pages/Panel/Product/Details/ProductDetails';
@import 'src/app/pages/Panel/Reservation/Report/ReservationReport';
@import 'src/app/pages/Panel/Reservation/Details/ReservationDetails';
@import 'src/app/pages/Panel/TableRestaurant/Report/TableRestaurantReport';
@import 'src/app/pages/Panel/TableRestaurant/Details/TableRestaurantDetails';
@import 'src/app/pages/Panel/Poster/Banner/Report/BannerReport';
@import 'src/app/pages/Panel/Poster/Banner/Details/BannerDetails';
@import 'src/app/pages/Panel/Poster/Campaign/Report/CampaignReport';
@import 'src/app/pages/Panel/Poster/Campaign/Details/CampaignDetails';
@import 'src/app/pages/Panel/Coupon/Report/CouponReport';
@import 'src/app/pages/Panel/Coupon/Details/CouponDetails';

// components
@import 'src/app/components/AdvancedInput/AdvancedInput';
@import 'src/app/components/AdvancedButton/AdvancedButton';
@import 'src/app/components/AdvancedForm/AdvancedForm';
@import 'src/app/components/AdvancedSelect/AdvancedSelect';
@import 'src/app/components/AdvancedRadio/AdvancedRadio';
@import 'src/app/components/AdvancedDateRangePicker/AdvancedDateRangePicker';
@import 'src/app/components/AdvancedDateTimePicker/AdvancedDateTimePicker';
@import 'src/app/components/AdvancedCheckbox/AdvancedCheckbox';
@import 'src/app/components/AdvancedFileInput/AdvancedFileInput';
@import 'src/app/components/AdvancedImage/AdvancedImage';
@import 'src/app/components/AdvancedPopOver/AdvancedPopOver';
@import 'src/app/components/AdvancedUploader/AdvancedUploader';
@import 'src/app/components/DataTable/DataTable';
@import 'src/app/components/PanelSidebar/PanelSidebar';
@import 'src/app/components/PanelSidebarMenu/PanelSidebarMenu';
@import 'src/app/components/PanelContent/PanelContent';
@import 'src/app/components/PanelContentHeader/PanelContentHeader';
@import 'src/app/components/PanelContentTopBar/PanelContentTopBar';
@import 'src/app/components/PanelContentSearchBar/PanelContentSearchBar';
@import 'src/app/components/Loading/Loading';
@import 'src/app/components/DataTableActions/DataTableActions';
@import 'src/app/components/AdvancedFilters/AdvancedFilters';
@import 'src/app/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
@import 'src/app/components/KPICard/KPICard';
@import 'src/app/components/AdvancedCalendar/AdvancedCalendar';

@primary-color: #E82D23;