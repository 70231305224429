.advanced-date-rangepicker {
  padding-bottom: 30px;
  height: 100%;

  &__label {
    display: block;
    width: 100%;

    &__inner {
      display: block;
      padding-bottom: 45px;
    }
  }
}
