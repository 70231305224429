.kpi-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: @card-bg;
  border-radius: 5px;
  border: 1px solid rgb(225, 225, 225, 0.7);
  padding: 10px 10px;

  &__icon {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__img {
      width: 70%;
      height: 70%;
    }
  }

  &__right {
    width: 30%;

    &__number {
      font-size: 13pt;
      font-weight: bold;
    }

    &__description {
      font-size: 10pt;
    }
  }
}
