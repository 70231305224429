.panel-sidebar {
  width: 240px;
  min-height: 100vh;
  height: 100%;
  background-color: @light-pink-bg;

  &__logo {
    padding: 30px;

    &__img {
      max-width: 65%;
      margin: 0 auto;
      display: flex;
    }
  }

  &__menu {

  }
}
