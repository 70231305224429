.password-recovery {
  background-color: @light-pink-bg;
  min-height: 100vh;
  height: 100%;

  &__form {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    flex-direction: column;

    &__inner {
      margin-bottom: 10em;

      &__logo {
        display: block;
        margin: 30px auto;
        max-width: 100%;
        width: 247px;
        height: 150px;
      }

      &__card {
        background-color: #FAFAFA;
        padding: 36px 34px 23px;
        border: 1px solid #FAFAFA;
      }

      &__message {
        text-align: center;

        &__title {
          display: block;
          padding-bottom: 15px;
        }

        &__warning {
          text-align: center;
          padding-bottom: 15px;
        }
      }

      &__button {
        padding-top: 42px;
      }

      &__bottom {
        text-align: center;
        padding-top: 30px;
      }
    }
  }
}
