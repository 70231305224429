.advanced-popover {
  display: block;
  width: 100%;

  &__icon {
    display: inline-block;
    width: 1.5em;
    text-align: left;
    margin-top: -0.25em;
  }
}
