.advanced-image {
  display: block;
  width: 100%;
  padding-bottom: 15px;

  &__preview {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 50%;
    height: 50%;
    padding: 20px;
    margin-top: -5px;

    &__img {
      width: 100%;
      height: 100%;
    }
  }
}
