.shopkeeper {
  width: 100%;
  height: 100%;

  &__advanced-filters {

    &__item {

      &--button {
        padding-top: 15px;
      }
    }
  }

  &__table {
    margin-top: 30px;
    height: 42.5em;
  }

  &__details {

    &__form {

      &__title {
        padding: 30px 0 15px;

        &__text {
          font-size: 15pt;
        }
      }
    }
  }
}
