.category {
  width: 100%;
  height: 100%;

  &__advanced-filters {

    &__item {

      &--button {
        padding-top: 15px;
      }
    }
  }

  &__table {
    margin-top: 30px;
    height: 42.5em;
  }

  &__details {

    &__form {

      &__title {
        padding: 30px 0 15px;

        &__text {
          font-size: 15pt;
        }
      }

      &__list {
        padding: 15px 0 15px;
        width: 100%;

        &__text {
          font-size: 13pt;
        }

        &__item {

          &__pic {
            margin: 0 auto;
            display: block;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__img {
              border-radius: 50%;
              height: 70px;
              width: 70px;
            }
          }

          &__advanced-button {
            margin: -30px 0;
          }
        }
      }
    }
  }
}
