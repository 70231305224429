.banner-campaign {
  width: 100%;
  height: 100%;

  &__advanced-filters {
    
    &__item {
      
      &--button {
        padding-top: 15px;
      }
    }
  }

  &__table {
    height: 42.5em;
  }
  .advanced-button {
    display: flex !important;
    justify-content: flex-end;
    margin: 30px 0;
  }
}
